import React from 'react'
import { Button } from '@saatva-bits/pattern-library.components.button'

import * as productCodes from '@/constants/product-codes'

import rugs from './rugs'
import rightBedSetup from './rightBedSetup'

/*
This config should be imported into the component for use.
Any named files in this directory are intended as reusable units exclusively for this file.
*/

/*
Content has been designed to take standard HTML tags and format them consistently.
The currently styled and supported tags for the content elements are:
- h3, h4
- p
- ul, li
- em, strong, small (reduces text size, like for "* disclaimers")
*/

const config = {

    [productCodes.SAATVA_CLASSIC]: rightBedSetup,
    [productCodes.SAATVA_LATEX_HYBRID]: rightBedSetup,
    [productCodes.LOOM_AND_LEAF]: rightBedSetup,
    [productCodes.SAATVA_CONTOUR5]: rightBedSetup,
    [productCodes.SOLAIRE]: rightBedSetup,
    [productCodes.MEMORY_FOAM_HYBRID]: rightBedSetup,
    [productCodes.SAATVA_RX]: rightBedSetup,
    [productCodes.ZENHAVEN]: rightBedSetup,
    [productCodes.SAATVA_HD]: rightBedSetup,

    [productCodes.KANAN_BEDROOM_RUG]: rugs,
    [productCodes.TERNA_BEDROOM_RUG]: rugs,
    [productCodes.ADAMAS_BEDROOM_RUG]: rugs,
    [productCodes.CIRRUS_BEDROOM_RUG]: rugs,
    [productCodes.MYRA_BEDROOM_RUG]: rugs,
    [productCodes.NAVI_BEDROOM_RUG]: rugs,

    [productCodes.ADJUSTABLE_BASE_PLUS]: {
        title: 'Upgrade your comfort with the touch of a button',
        tabAsHeader: true,
        content: [
            {
                tab: 'Full body massage',
                content () {
                    return (
                        <>
                            <h4>Head Massage:</h4>
                            <p>3 intensities of vibrating massage for the top half of your torso*</p>
                            <h4>Full-body wave massage:</h4>
                            <p>3 intensities of vibrating massage for your entire body*</p>
                            <h4>Foot/leg massage:</h4>
                            <p>3 intensities of vibrating massage for your lower torso*</p>
                            <p><small><em>* Massage automatically shuts off after 15 minutes.</em></small></p>
                        </>
                    )
                },
                image: {
                    folder: 'products/adjustable-base-plus/full-body-massage',
                    file: 'adjustable-base-plus-full-body-massage-16-9.jpg'
                }
            },
            {
                tab: 'Preset settings',
                content () {
                    return (
                        <>
                            <p>Enjoy instant comfort with one touch of a button. Preset settings include:</p>
                            <h4>Head up preset:</h4>
                            <p>Elevates the head of the bed to its maximum height with one touch.</p>
                            <h4>Flat preset:</h4>
                            <p>Lowers the bed back to its flat position with one touch.</p>
                            <h4>Anti-snore preset:</h4> 
                            <p>Elevates the head of the base at the ideal 7 degrees to open up airways and reduce snoring.</p>
                            <h4>Zero-gravity preset:</h4>
                            <p>Feel like you’re floating in weightless comfort with one-touch, perfectly balanced head & foot support.</p>
                            <h4>Memory preset:</h4>
                            <p>Save your favorite positions for sleeping, watching TV, or just relaxing.</p>
                        </>
                    )
                },
                image: {
                    folder: 'products/adjustable-base-plus/wall-hugging',
                    file: 'adjustable-base-plus-wall-hugging-16-9.jpg'
                }
            },
            {
                tab: 'Under-bed lighting',
                content () {
                    return (
                        <p>Provides soft LED lighting without disturbing the peace, perfect for middle-of-the-night bathroom or kitchen trips.</p>
                    )
                },
                image: {
                    folder: 'products/adjustable-base-plus/under-bed-lighting',
                    file: 'adjustable-base-plus-under-bed-lighting-16-9.jpg'
                }
            },
            {
                tab: 'More functions',
                content () {
                    return (
                        <>
                            <h4>In-remote flashlight:</h4>
                            <p>Your remote doubles as a mini flashlight to help you find your way around in the dark.</p>
                            <h4>Safety lock/unlock:</h4>
                            <p>Prevents accidental button presses.</p>
                            <h4>Head & Foot up & down:</h4>
                            <p>Elevate and lower the head & foot of the bed incrementally.</p>
                        </>
                    )
                },
                image: {
                    folder: 'products/adjustable-base-plus/remote',
                    file: 'adjustable-base-plus-remote-16-9.jpg'
                }
            }
        ]
    },

    // TOPPERS

    [productCodes.LATEX_MATTRESS_TOPPER]: {
        title: 'We have a mattress topper for every sleep need',
        folder: 'additional-features-pdp/toppers',
        tabAsHeader: true,
        content: [
            {
                tab: 'Natural latex',
                content (scrollTo) {
                    return (
                        <>
                            <h4>For durability, pressure relief & a responsive, buoyant feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially back sleepers</li>
                                <li>Natural latex offers unmatched pressure relief, breathability, and durability</li>
                                <li>Made with eco-friendly, natural materials</li>
                            </ul>
                            <Button kind="primary" onClick={() => scrollTo()}>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/latex-mattress-topper/layers',
                    file: 'latex-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Graphite memory foam',
                content () {
                    return (
                        <>
                            <h4>For pressure relief, cooler sleep &amp; a deep, body-hugging feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Hugs your curves for great pressure relief where you need it most</li>
                                <li>Sleeps cooler with graphite-infused memory foam that draws body heat away</li>
                            </ul>
                            <Button kind="primary" href="/bedding/graphite-memory-foam-mattress-topper">
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/graphite-memory-foam-mattress-topper/layers',
                    file: 'graphite-memory-foam-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'High-density foam',
                content () {
                    return (
                        <>
                            <h4>For pressure relief & a medium body-contouring feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Instantly adds pressure relief &amp; medium cushioning to any mattress</li>
                                <li>Most affordable option to prolong the life of an older mattress</li>
                            </ul>
                            <Button kind="primary" href='/bedding/high-density-foam-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/high-density-foam-mattress-topper/layers',
                    file: 'high-density-foam-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Down alternative',
                content () {
                    return (
                        <>
                            <h4>For an ultra-plush feel &amp; breathable comfort</h4>
                            <ul>
                                <li>Great for side sleepers</li>
                                <li>Adds an extra layer of plush comfort to alleviate pressure points</li>
                                <li>Great for hot, sweaty sleepers with a breathable, moisture-wicking fill of down alternative and lyocell</li>
                            </ul>
                            <Button kind="primary" href='/bedding/featherbed-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/featherbed-mattress-topper/layers',
                    file: 'featherbed-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Micro-coils',
                content () {
                    return (
                        <>
                            <h4>For a responsive feel, body-contouring support &amp; pressure relief</h4>
                            <ul>
                                <li>Great for all sleep positions</li>
                                <li>Hugs your curves for great pressure relief where you need it most</li>
                                <li>Sleeps cooler with gel-infused comfort foam</li>
                            </ul>
                            <Button kind="primary" href='/bedding/microcoil-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/microcoil-mattress-topper/layers',
                    file: 'microcoil-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Organic quilted',
                content () {
                    return (
                        <>
                            <h4>For the ultimate in plush, weightless comfort & pressure relief</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Plush, quilted wool batting plus natural latex for unmatched pressure relief, breathability & cushioning</li>
                                <li>GOTS® certified organic—our most eco-friendly mattress topper option</li>
                            </ul>
                            <Button kind="primary" href='/bedding/organic-quilted-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/organic-quilted-mattress-topper/layers',
                    file: 'organic-quilted-mattress-topper-layers-16-9.jpg'
                }
            }
        ]
    },
    [productCodes.GRAPHITE_MEMORY_FOAM_MATTRESS_TOPPER]: {
        title: 'We have a mattress topper for every sleep need',
        folder: 'additional-features-pdp/toppers',
        tabAsHeader: true,
        content: [
            {
                tab: 'Graphite memory foam',
                content (scrollTo) {
                    return (
                        <>
                            <h4>For pressure relief, cooler sleep &amp; a deep, body-hugging feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Hugs your curves for great pressure relief where you need it most</li>
                                <li>Sleeps cooler with graphite-infused memory foam that draws body heat away</li>
                            </ul>
                            <Button kind="primary" onClick={() => scrollTo()}>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/graphite-memory-foam-mattress-topper/layers',
                    file: 'graphite-memory-foam-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'High-density foam',
                content () {
                    return (
                        <>
                            <h4>For pressure relief & a medium body-contouring feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Instantly adds pressure relief & medium cushioning to any mattress</li>
                                <li>Most affordable option to prolong the life of an older mattress</li>
                            </ul>
                            <Button kind="primary" href="/bedding/high-density-foam-mattress-topper">
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/high-density-foam-mattress-topper/layers',
                    file: 'high-density-foam-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Down alternative',
                content () {
                    return (
                        <>
                            <h4>For an ultra-plush feel &amp; breathable comfort</h4>
                            <ul>
                                <li>Great for side sleepers</li>
                                <li>Adds an extra layer of plush comfort to alleviate pressure points</li>
                                <li>Great for hot, sweaty sleepers with a breathable, moisture-wicking fill of down alternative and lyocell</li>
                            </ul>
                            <Button kind="primary" href='/bedding/featherbed-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/featherbed-mattress-topper/layers',
                    file: 'featherbed-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Natural latex',
                content () {
                    return (
                        < >
                            <h4>For durability, pressure relief & a responsive, buoyant feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially back sleepers</li>
                                <li>Natural latex offers unmatched pressure relief, breathability, and durability</li>
                                <li>Made with eco-friendly, natural materials</li>
                            </ul>
                            <Button kind="primary" href="/bedding/latex-mattress-topper">
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/latex-mattress-topper/layers',
                    file: 'latex-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Micro-coils',
                content () {
                    return (
                        <>
                            <h4>For a responsive feel, body-contouring support &amp; pressure relief</h4>
                            <ul>
                                <li>Great for all sleep positions</li>
                                <li>Hugs your curves for great pressure relief where you need it most</li>
                                <li>Sleeps cooler with gel-infused comfort foam</li>
                            </ul>
                            <Button kind="primary" href='/bedding/microcoil-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/microcoil-mattress-topper/layers',
                    file: 'microcoil-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Organic quilted',
                content () {
                    return (
                        <>
                            <h4>For the ultimate in plush, weightless comfort & pressure relief</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Plush, quilted wool batting plus natural latex for unmatched pressure relief, breathability & cushioning</li>
                                <li>GOTS® certified organic—our most eco-friendly mattress topper option</li>
                            </ul>
                            <Button kind="primary" href='/bedding/organic-quilted-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/organic-quilted-mattress-topper/layers',
                    file: 'organic-quilted-mattress-topper-layers-16-9.jpg'
                }
            }
        ]
    },
    [productCodes.HIGH_DENSITY_FOAM_MATTRESS_TOPPER]: {
        title: 'We have a mattress topper for every sleep need',
        folder: 'additional-features-pdp/toppers',
        tabAsHeader: true,
        content: [
            {
                tab: 'High-density foam',
                content (scrollTo) {
                    return (
                        <>
                            <h4>For pressure relief & a medium body-contouring feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Instantly adds pressure relief & medium cushioning to any mattress</li>
                                <li>Most affordable option to prolong the life of an older mattress</li>
                            </ul>
                            <Button kind="primary" onClick={() => scrollTo()}>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/high-density-foam-mattress-topper/layers',
                    file: 'high-density-foam-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Graphite memory foam',
                content () {
                    return (
                        <>
                            <h4>For pressure relief, cooler sleep &amp; a deep, body-hugging feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Hugs your curves for great pressure relief where you need it most</li>
                                <li>Sleeps cooler with graphite-infused memory foam that draws body heat away</li>
                            </ul>
                            <Button kind="primary" href='/bedding/graphite-memory-foam-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/graphite-memory-foam-mattress-topper/layers',
                    file: 'graphite-memory-foam-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Down alternative',
                content () {
                    return (
                        <>
                            <h4>For an ultra-plush feel &amp; breathable comfort</h4>
                            <ul>
                                <li>Great for side sleepers</li>
                                <li>Adds an extra layer of plush comfort to alleviate pressure points</li>
                                <li>Great for hot, sweaty sleepers with a breathable, moisture-wicking fill of down alternative and lyocell</li>
                            </ul>
                            <Button kind="primary" href='/bedding/featherbed-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/featherbed-mattress-topper/layers',
                    file: 'featherbed-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Natural latex',
                content () {
                    return (
                        < >
                            <h4>For durability, pressure relief & a responsive, buoyant feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially back sleepers</li>
                                <li>Natural latex offers unmatched pressure relief, breathability, and durability</li>
                                <li>Made with eco-friendly, natural materials</li>
                            </ul>
                            <Button kind="primary" href='/bedding/latex-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/latex-mattress-topper/layers',
                    file: 'latex-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Micro-coils',
                content () {
                    return (
                        <>
                            <h4>For a responsive feel, body-contouring support &amp; pressure relief</h4>
                            <ul>
                                <li>Great for all sleep positions</li>
                                <li>Hugs your curves for great pressure relief where you need it most</li>
                                <li>Sleeps cooler with gel-infused comfort foam</li>
                            </ul>
                            <Button kind="primary" href='/bedding/microcoil-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/microcoil-mattress-topper/layers',
                    file: 'microcoil-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Organic quilted',
                content () {
                    return (
                        <>
                            <h4>For the ultimate in plush, weightless comfort & pressure relief</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Plush, quilted wool batting plus natural latex for unmatched pressure relief, breathability & cushioning</li>
                                <li>GOTS® certified organic—our most eco-friendly mattress topper option</li>
                            </ul>
                            <Button kind="primary" href='/bedding/organic-quilted-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/organic-quilted-mattress-topper/layers',
                    file: 'organic-quilted-mattress-topper-layers-16-9.jpg'
                }
            }
        ]
    },
    [productCodes.MICROCOIL_MATTRESS_TOPPER]: {
        title: 'We have a mattress topper for every sleep need',
        folder: 'additional-features-pdp/toppers',
        tabAsHeader: true,
        content: [
            {
                tab: 'Micro-coils',
                content (scrollTo) {
                    return (
                        <>
                            <h4>For a responsive feel, body-contouring support &amp; pressure relief</h4>
                            <ul>
                                <li>Great for all sleep positions</li>
                                <li>Hugs your curves for great pressure relief where you need it most</li>
                                <li>Sleeps cooler with gel-infused comfort foam</li>
                            </ul>
                            <Button kind="primary" onClick={() => scrollTo()}>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/microcoil-mattress-topper/layers',
                    file: 'microcoil-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Graphite memory foam',
                content () {
                    return (
                        <>
                            <h4>For pressure relief, cooler sleep &amp; a deep, body-hugging feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Hugs your curves for great pressure relief where you need it most</li>
                                <li>Sleeps cooler with graphite-infused memory foam that draws body heat away</li>
                            </ul>
                            <Button kind="primary" href='/bedding/graphite-memory-foam-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/graphite-memory-foam-mattress-topper/layers',
                    file: 'graphite-memory-foam-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'High-density foam',
                content () {
                    return (
                        <>
                            <h4>For pressure relief & a medium body-contouring feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Instantly adds pressure relief &amp; medium cushioning to any mattress</li>
                                <li>Most affordable option to prolong the life of an older mattress</li>
                            </ul>
                            <Button kind="primary" href='/bedding/high-density-foam-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/high-density-foam-mattress-topper/layers',
                    file: 'high-density-foam-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Down alternative',
                content () {
                    return (
                        <>
                            <h4>For an ultra-plush feel &amp; breathable comfort</h4>
                            <ul>
                                <li>Great for side sleepers</li>
                                <li>Adds an extra layer of plush comfort to alleviate pressure points</li>
                                <li>Great for hot, sweaty sleepers with a breathable, moisture-wicking fill of down alternative and lyocell</li>
                            </ul>
                            <Button kind="primary" href='/bedding/featherbed-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/featherbed-mattress-topper/layers',
                    file: 'featherbed-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Natural latex',
                content () {
                    return (
                        < >
                            <h4>For durability, pressure relief & a responsive, buoyant feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially back sleepers</li>
                                <li>Natural latex offers unmatched pressure relief, breathability, and durability</li>
                                <li>Made with eco-friendly, natural materials</li>
                            </ul>
                            <Button kind="primary" href='/bedding/latex-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/latex-mattress-topper/layers',
                    file: 'latex-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Organic quilted',
                content () {
                    return (
                        <>
                            <h4>For the ultimate in plush, weightless comfort & pressure relief</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Plush, quilted wool batting plus natural latex for unmatched pressure relief, breathability & cushioning</li>
                                <li>GOTS® certified organic—our most eco-friendly mattress topper option</li>
                            </ul>
                            <Button kind="primary" href='/bedding/organic-quilted-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/organic-quilted-mattress-topper/layers',
                    file: 'organic-quilted-mattress-topper-layers-16-9.jpg'
                }
            }
        ]
    },
    [productCodes.FEATHERBED_MATTRESS_TOPPER]: {
        title: 'We have a mattress topper for every sleep need',
        folder: 'additional-features-pdp/toppers',
        tabAsHeader: true,
        content: [
            {
                tab: 'Down alternative',
                content (scrollTo) {
                    return (
                        <>
                            <h4>For an ultra-plush feel &amp; breathable comfort</h4>
                            <ul>
                                <li>Great for side sleepers</li>
                                <li>Adds an extra layer of plush comfort to alleviate pressure points</li>
                                <li>Great for hot, sweaty sleepers with a breathable, moisture-wicking fill of down alternative and lyocell</li>
                            </ul>
                            <Button kind="primary" onClick={() => scrollTo()}>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/featherbed-mattress-topper/layers',
                    file: 'featherbed-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Graphite memory foam',
                content () {
                    return (
                        <>
                            <h4>For pressure relief, cooler sleep &amp; a deep, body-hugging feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Hugs your curves for great pressure relief where you need it most</li>
                                <li>Sleeps cooler with graphite-infused memory foam that draws body heat away</li>
                            </ul>
                            <Button kind="primary" href='/bedding/graphite-memory-foam-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/graphite-memory-foam-mattress-topper/layers',
                    file: 'graphite-memory-foam-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'High-density foam',
                content () {
                    return (
                        <>
                            <h4>For pressure relief & a medium body-contouring feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Instantly adds pressure relief &amp; medium cushioning to any mattress</li>
                                <li>Most affordable option to prolong the life of an older mattress</li>
                            </ul>
                            <Button kind="primary" href='/bedding/high-density-foam-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/high-density-foam-mattress-topper/layers',
                    file: 'high-density-foam-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Natural latex',
                content () {
                    return (
                        < >
                            <h4>For durability, pressure relief & a responsive, buoyant feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially back sleepers</li>
                                <li>Natural latex offers unmatched pressure relief, breathability, and durability</li>
                                <li>Made with eco-friendly, natural materials</li>
                            </ul>
                            <Button kind="primary" href='/bedding/latex-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/latex-mattress-topper/layers',
                    file: 'latex-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Micro-coils',
                content () {
                    return (
                        <>
                            <h4>For a responsive feel, body-contouring support &amp; pressure relief</h4>
                            <ul>
                                <li>Great for all sleep positions</li>
                                <li>Hugs your curves for great pressure relief where you need it most</li>
                                <li>Sleeps cooler with gel-infused comfort foam</li>
                            </ul>
                            <Button kind="primary" href='/bedding/microcoil-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/microcoil-mattress-topper/layers',
                    file: 'microcoil-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Organic quilted',
                content () {
                    return (
                        <>
                            <h4>For the ultimate in plush, weightless comfort & pressure relief</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Plush, quilted wool batting plus natural latex for unmatched pressure relief, breathability & cushioning</li>
                                <li>GOTS® certified organic—our most eco-friendly mattress topper option</li>
                            </ul>
                            <Button kind="primary" href='/bedding/organic-quilted-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/organic-quilted-mattress-topper/layers',
                    file: 'organic-quilted-mattress-topper-layers-16-9.jpg'
                }
            }
        ]
    },
    [productCodes.ORGANIC_QUILTED_MATTRESS_TOPPER]: {
        title: 'We have a mattress topper for every sleep need',
        folder: 'additional-features-pdp/toppers',
        tabAsHeader: true,
        content: [
            {
                tab: 'Organic quilted',
                content (scrollTo) {
                    return (
                        <>
                            <h4>For the ultimate in plush, weightless comfort & pressure relief</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Plush, quilted wool batting plus natural latex for unmatched pressure relief, breathability & cushioning</li>
                                <li>GOTS® certified organic—our most eco-friendly mattress topper option</li>
                            </ul>
                            <Button kind="primary" onClick={() => scrollTo()}>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/organic-quilted-mattress-topper/layers',
                    file: 'organic-quilted-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Graphite memory foam',
                content () {
                    return (
                        <>
                            <h4>For pressure relief, cooler sleep &amp; a deep, body-hugging feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Hugs your curves for great pressure relief where you need it most</li>
                                <li>Sleeps cooler with graphite-infused memory foam that draws body heat away</li>
                            </ul>
                            <Button kind="primary" href='/bedding/graphite-memory-foam-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/graphite-memory-foam-mattress-topper/layers',
                    file: 'graphite-memory-foam-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'High-density foam',
                content () {
                    return (
                        <>
                            <h4>For pressure relief & a medium body-contouring feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially side sleepers</li>
                                <li>Instantly adds pressure relief &amp; medium cushioning to any mattress</li>
                                <li>Most affordable option to prolong the life of an older mattress</li>
                            </ul>
                            <Button kind="primary" href='/bedding/high-density-foam-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/high-density-foam-mattress-topper/layers',
                    file: 'high-density-foam-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Down alternative',
                content () {
                    return (
                        <>
                            <h4>For an ultra-plush feel &amp; breathable comfort</h4>
                            <ul>
                                <li>Great for side sleepers</li>
                                <li>Adds an extra layer of plush comfort to alleviate pressure points</li>
                                <li>Great for hot, sweaty sleepers with a breathable, moisture-wicking fill of down alternative and lyocell</li>
                            </ul>
                            <Button kind="primary" href='/bedding/featherbed-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/featherbed-mattress-topper/layers',
                    file: 'featherbed-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Natural latex',
                content () {
                    return (
                        < >
                            <h4>For durability, pressure relief & a responsive, buoyant feel</h4>
                            <ul>
                                <li>Great for all sleep positions, especially back sleepers</li>
                                <li>Natural latex offers unmatched pressure relief, breathability, and durability</li>
                                <li>Made with eco-friendly, natural materials</li>
                            </ul>
                            <Button kind="primary" href='/bedding/latex-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/latex-mattress-topper/layers',
                    file: 'latex-mattress-topper-layers-16-9.jpg'
                }
            },
            {
                tab: 'Micro-coils',
                content () {
                    return (
                        <>
                            <h4>For a responsive feel, body-contouring support &amp; pressure relief</h4>
                            <ul>
                                <li>Great for all sleep positions</li>
                                <li>Hugs your curves for great pressure relief where you need it most</li>
                                <li>Sleeps cooler with gel-infused comfort foam</li>
                            </ul>
                            <Button kind="primary" href='/bedding/microcoil-mattress-topper'>
                                Shop Now
                            </Button>
                        </>
                    )
                },
                image: {
                    folder: 'products/microcoil-mattress-topper/layers',
                    file: 'microcoil-mattress-topper-layers-16-9.jpg'
                }
            }
        ]
    }
}

export default config
