import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import config from './config'
import PictureImgix from '../PictureImgix'
import useDeviceType from '@/hooks/useDeviceType'
import { AccordionSet } from '@saatva-bits/pattern-library.components.accordion-set'
import { Accordion } from '@saatva-bits/pattern-library.components.accordion'
import { scrollToElement, OFFSETS } from '@saatva-bits/pattern-library.utils.position'
import styles from './AdditionalFeaturesPDP.module.scss'
import { Button } from '@saatva-bits/pattern-library.components.button'

const AdditionalFeaturesPDP = ({
    className,
    productCode
}) => {
    const [activePanel, setActivePanel] = useState(0)
    const featuresContent = config[`${productCode}`] || {}
    const { isDesktop } = useDeviceType('desktop')

    const widths = {
        mobile: 375,
        tablet: 678,
        desktop: 537
    }

    const scrollTo = () => {
        scrollToElement('productPanel', OFFSETS.both, 50)
    }

    const renderPicture = (image, altText) => {
        const folder = image.folder || featuresContent.folder
        const file = image.file || image

        // Using this as a mechanism to track assets converted to ARDAD.
        // Config for images using ARDAD should update to the object convention.
        // to define the file (even if using the default folder)
        // Once all assets are converted, this check (and the prefixOverride) can be removed.
        const isConvertedToArdad = image.file
        const prefixOverride = !isConvertedToArdad ? {
            mobile: 'mobile',
            tablet: 'tablet',
            desktop: 'desktop'
        } : null

        return (
            <PictureImgix
                folder={folder}
                name={file}
                lazyLoad={false}
                className={styles.image}
                widths={widths}
                prefixOverride={prefixOverride}
                alt={altText}
            />
        )
    }

    const renderContent = (content, textTab) => {
        return (
            <div className={styles.content}>
                { featuresContent.tabAsHeader &&
                    <h3>{ textTab }</h3>
                }
                {content(scrollTo)}
            </div>
        )
    }

    const wrapperClasses = classNames(
        'section',
        styles.sectionWrapper,
        className,
        { [styles.lightMode]: featuresContent.lightMode }
    )
    return (
        <section id='tabbedFeatures' className={wrapperClasses}>
            <div className="container u-paddingVertical--base u-paddingBottom--2dot5xl">
                <h2>{featuresContent.title}</h2>
                { featuresContent.description &&
                    <p className='u-paddingTop u-textCenter'>{featuresContent.description}</p>
                }
            </div>
            {isDesktop
                ? <React.Fragment>
                    <div className='container'>
                        <div className={styles.tabbedFeatures__nav}>
                            { featuresContent.content.map((tabContent, i) => {
                                const navItemClasses = classNames(
                                    styles.tabbedFeatures__navItem,
                                    { [styles['tabbedFeatures__navItem--active']]: i === activePanel }
                                )
                                return <Button className={navItemClasses} onClick={() => setActivePanel(i)} key={tabContent.tab}>
                                    {tabContent.tab}
                                </Button>
                            })}
                        </div>
                        <div>
                            { featuresContent.content.map((tabContent, i) =>{
                                const tabContentClasses = classNames(
                                    styles.tabbedFeatures__contentWrapper,
                                    { [styles['tabbedFeatures__contentWrapper--active']]: i === activePanel }
                                )
                                return <div key={ tabContent.tab } className={tabContentClasses}>
                                    { featuresContent.leftImage
                                        ? <>
                                            {renderPicture(tabContent.image, tabContent.tab)}
                                            {renderContent(tabContent.content, tabContent.tab)}
                                        </>
                                        : <>
                                            {renderContent(tabContent.content, tabContent.tab)}
                                            {renderPicture(tabContent.image, tabContent.tab)}
                                        </>
                                    }
                                </div>
                            })}
                        </div>
                    </div>
                </React.Fragment>
                : <React.Fragment>
                    <AccordionSet
                        className={styles.accordionFeatures}
                        accordionData={ featuresContent.content.map((tabContent) => {
                            return {
                                title: tabContent.tab,
                                content: (
                                    <div key={ tabContent.tab } className={styles.accordionFeatures__contentWrapper}>
                                        { renderPicture(tabContent.image) }
                                        <div className={`${styles.content} t-family--serif`}>
                                            { featuresContent.tabAsHeader &&
                                                <h3>{tabContent.tab}</h3>
                                            }
                                            {tabContent.content(scrollTo)}
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    >
                        <Accordion
                            className={styles.accordionItem}
                            titleClassName={styles.accordionItem__title}
                        />
                    </AccordionSet>
                </React.Fragment>
            }
        </section>
    )
}

AdditionalFeaturesPDP.propTypes = {
    className: PropTypes.string,
    productCode: PropTypes.string
}

export default AdditionalFeaturesPDP
